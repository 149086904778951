// var oldOnError = window.onerror;
//
// if (!document.location.href.match(/http:\/\/(localhost|127\.0\.0\.1)/)) {
// 	window.onerror = function(err, url, line) {
// 		if (oldOnError) {
// 			try {
// 				oldOnError(err, url, line);
// 			} catch (e) {}
// 		}
// 		try {
// 			hs.logToServer('ERR:' + err + '(' + url + ':' + line + ')');
// 		} catch (e) {}
// 		return true;
// 	};
// }

// export {};
require('./feature-detection');

import {ensureHasId} from './hs-util';

require('./jQuery.tipTip');
require('./forgive-form-double-click');

$.fn.followScrolling = function(this:JQuery): JQuery {

  return this.each(function() {
    var $follower = $(this);
    var followerTop = $follower.offset().top;
    $(window).scroll(function() {
      var scrollTop = $(window).scrollTop();
      if (scrollTop > followerTop) {
        if ($follower.css('position') !== 'fixed') {
          $follower.css({
            position: 'fixed',
            top: 0,
            left: $follower.offset().left,
          }).addClass('following-now');
        }
      } else {
        $follower.css({'position': 'static'}).removeClass('following-now');
      }

    }).scroll();

    var winWidth = $(window).width();
    var delayAdjust:NodeJS.Timer|null = null;
    $(window).on('resize', function() {

      if (delayAdjust !== null) {
        clearTimeout(delayAdjust);
      }

      // delay adjustment until half a second after the last resize event,
      // this way the element won't repeatedly flicker while resizing the window
      delayAdjust = setTimeout(function() {
        if (delayAdjust !== null) {
          clearTimeout(delayAdjust);
          delayAdjust = null;
        }

        if ($follower.css('position') === 'fixed') {

          // if width hasn't changed, return, we don't need to recalculate left
          if (winWidth === $(window).width()) {
            return;
          }
          winWidth = $(window).width();

          $follower.css({'position': 'static'});
          setTimeout(function() {
            $follower.css({
              position: 'fixed',
              top: 0,
              left: $follower.offset().left,
            });
          }, 50);
        }

      }, 500);
    }).resize();
  });
};

$.fn.verticallyScrolledIn = function() {

  var min = $(window).scrollTop();
  var max = min + $(window).height();

  return this.filter(function() {
    var $t = $(this);
    var top = $t.offset().top;

    if (top >= min && top <= max) {
      return true;
    }

    var bottom = top + $t.height();

    if (bottom >= min && bottom <= max) {
      return true;
    }

    if (top < min && bottom > max) {
      return true;
    }

    return false;
  });

};

$(document).ajaxSend(function(ev, xhr, settings) {
  if (!window.hs || !hs.csrf_re) {
    return;
  }
  if (settings.type.search(/^POST/i) === -1) {
    return;
  }

  var result = hs.csrf_re.exec(document.cookie);
  if (result) {
    xhr.setRequestHeader('X-CSRFToken', result[1]);
  }
});

var hs = window.hs = $.extend(window.hs || {}, {
  csrf_re: new RegExp('csrftoken=([^;]+)(;|$)'),
  wmvMode: false,
  wmvState: '??',
  continueFromQuestion: $.noop,
  init: function() {
    // This option appends a _=$.now() to all GET ajax requests
    // This solves an issue with IE users not
    // getting their videos marked finished. IE was caching the result
    // of the first ajax call and then
    // never called the server again.
    $.ajaxSetup({cache: false});
    hs.loadTime = Date.now();
    hs.initDateOfBirthValidation();
    hs.initNotificationDismiss();
    hs.initCommentSafety();
    hs.initStripeForm();
    hs.initAjaxErrorReadout();
    hs.initPlot();
    hs.initNextLessonLink();
    hs.initWhyLocked();
    hs.initOrientationTip();
    hs.initCheckPresets();
    hs.initExamAnswerLogging();
    hs.initAutoSizeAspect();

    $(document).ajaxComplete(hs.ajaxComplete);
    hs.ajaxComplete();
  },

  _pointerClass: 'image-pointer',
  _pointerMods: {},
  getPointerMods: function() {
    if (!hs._pointerMods[hs._pointerClass]) {

      var $el = $('<div class="' + hs._pointerClass + '"></div>').appendTo('body').css(
        'visiblity',
        'hidden'
      );

      var imgName = $el.css('background-image');
      var regex = /^.*(up|down)(\d+)_(left|right)(\d+)\.(png|jpg|gif|jpeg).*/;
      var info = regex.exec(imgName);
      var mods;
      if (info) {
        mods = {
          x: parseInt(info[4]),
          y: parseInt(info[2]),
        };
        if (info[1] === 'up') {
          mods.y = -mods.y;
        }
        if (info[3] === 'left') {
          mods.x = -mods.x;
        }
      } else {
        mods = {
          x: 0,
          y: 0,
        };
      }
      hs._pointerMods[hs._pointerClass] = mods;
      $el.remove();
    }
    return hs._pointerMods[hs._pointerClass];
  },

  getPointerXMod: function() {
    return hs.getPointerMods().x;
  },

  getPointerYMod: function() {
    return hs.getPointerMods().y;
  },
  initAutoSizeAspect: function() {
    let doSizing = function() {
      $('*[data-auto-size-aspect]').each(function() {
        let $el = $(this);
        let w = $el.width();
        let aspect = parseFloat($el.attr('data-auto-size-aspect'));
        $el.height(Math.round(w * aspect));
        if ($el.attr('data-auto-size-child')) {
          for (let i = 0; i < $el[0].childElementCount; i++) {
            $($el[0].children[i]).height(Math.round(w * aspect));
          }
        }
      });
    };

    $(window).on('resize', doSizing);
    doSizing();
    setTimeout(doSizing, 1000);
  },
  ajaxComplete: function() {
    hs.initQuestionChoices();
    hs.adjustAnswerPointsPositions();
    hs.initAjaxForms();
    hs.initHideTheRest();
    hs.initUsernameCheck();
    hs.initSlideshow();
    hs.typesetMath();
    hs.initAjaxResultBefore();
    hs.updateLessonStatus();
    hs.initFigures();
    hs.initSticky();

    setTimeout(() => $(window).trigger('resize'), 100);
  },
  initSticky:function() {
    if (!window.Stickyfill) {
      return;
    }
    // Stickyfill.add(document.querySelectorAll('.sticky'));
    var $el = $('.sticky').not('.sticky-setup');
    $el.addClass('stick-setup');
    window.Stickyfill.add($el);
  },
  initDateOfBirthValidation: function() {
    /*
    I need to fix these kinds of problem dates
    09221957
    10161982
    16101982 (notice this is DDMMYYYY)
    12091987 (This could be Dec 9 or Sept 12)
    04 26, 1967
    1,16,1999
    jan,16,1999
    */

    var MONTHS = ['', 'January', 'February', 'March', 'April', 'May',
        'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    $('input[name="students_date_of_birth"]').on('blur', function(ev) {
      var dob = ($(ev.target).val() || '').trim();

      // 8 digit date with clear year first
      if (dob.match(/(19|20)\d{6}/)) {
        dob = dob.substr(0, 4) + '/' + dob.substr(4, 2) + '/' + dob.substr(6, 2);
      } else if (dob.match(/\d{5,8}/)) { // 6-to-8-digit maybe month first
        let month, day, year;
        if (dob.match(/\d{2,4}(19|20)\d\d$/)) { // definitely 4 digit year
          month = (dob.length < 8) ? parseInt(dob.substr(0, 1)) : parseInt(dob.substr(0, 2));
          day = (dob.length == 6) ? parseInt(dob.substr(dob.length - 5, 1)) : parseInt(dob.substr(dob.length - 6, 2));
          year = dob.substr(dob.length - 4, 4);
        } else { // I guess 2 digit year? 10655
          day = parseInt(dob.substr(dob.length - 4, 2));
          month = parseInt(dob.substr(0, dob.length - 4));
          year = dob.substr(dob.length - 2, 2);
        }
        if (month > 12 && day <= 12) {
          let tmp = month;
          month = day;
          day = tmp;
        }

        if (parseInt(month) === 0 && ('' + day).length === 2) {
          month = parseInt(('' + day).charAt(0));
          day = parseInt(('' + day).charAt(1));
        }

        if (year.length === 2) {
          if (parseInt(year) > ((new Date()).getFullYear() % 100 - 10)) {
            year = '19' + year;
          } else {
            year = '20' + year;
          }
        }

        dob = MONTHS[month] + ' ' + day + ', ' + year;
      }
      $(ev.target).val(dob);
    });
  },
  initExamAnswerLogging: function() {
    $('form#questions input[type="radio"]').on('change', function(ev) {
      try {
        var name = $(ev.target).attr('name');
        var val = $('form#questions input[name="' + name + '"]:checked').val();
        hs.logToServer('exam ' + name + ' set to ' + val);
      } catch (e) {}
    });
  },
  initNotificationDismiss: function() {
    $('.notification-panel .notification .dismiss').each(function() {
      var $dismiss = $(this);
      var $notification = $dismiss.parents('.notification').first();
      $dismiss.on('click', function(ev) {
        ev.preventDefault();
        $notification.remove();
      });
    });
  },
  initStripeForm: function() {

    let $tokenInput = $('form.hshs-stripe-payment input[name="stripe_id"]');
    if ($tokenInput.val()) {
      $tokenInput.parents('form').find('*[data-stripe]').hide();
    }

    $('form.hshs-stripe-payment').on('submit', function(ev) {
      var $form = $(this);

      var $tokenInput = $('input[name$="stripe_id"]', $(ev.target));
      if ($tokenInput.val() || $form.data('stripe-token-created')) {
        return;
      }

      $('button, input[type="submit"]', $form).prop('disabled', true);

      ev.preventDefault();

      window.Stripe.createToken($form, function(status, response) {
        $form.data('stripe-token-created', true);
        if (status === 200 && response && !response.error) {
          $tokenInput.val(response.id);
          $form.trigger('submit');
        } else if (response && response.error) {
          var errorText = response.error.message;
          try {
            console.log(response.error);
          } catch (e) {}

          if (response.error.code || response.error.type) {
            var codeType = ' (';
            if (response.error.type) {
              codeType += response.error.type + ', ';
            }
            if (response.error.code) {
              codeType += response.error.code;
            }
            errorText += codeType + ')';
          }

          $('.payment-errors', $form).text(errorText);
          $('button, input[type="submit"]', $form).prop('disabled', false);
        } else {
          $('.payment-errors', $form).text('Payment failed, please try again');
          $('button, input[type="submit"]', $form).prop('disabled', false);
        }
      });

      return false;
    });
  },
  initCheckPresets: function() {
    $('form a.check-presets[data-target]').on('click', function(ev) {
      ev.preventDefault();

      var $this = $(this);
      var values = $this.attr('href').substring(1).split(',');
      var $form = $this.parents('form').first();
      var target = $this.attr('data-target');
      $('input[name="' + target + '"]', $form).prop('checked', false);
      $.each(values, function(i, val) {
        $('input[name="' + target + '"]', $form)
          .filter('input[value="' + val + '"]')
          .prop('checked', true);
      });

    });
  },
  adjustAnswerPointsPositions: function() {
    $('.image-figure-image .' + hs._pointerClass + '.needs-adjustment').each(function() {
      var $t = $(this);
      var mods = hs.getPointerMods();
      var top = parseInt($t.css('top'));
      var left = parseInt($t.css('left'));
      $t.css({
        top: top + mods.y,
        left: left + mods.x,
      });
      $t.removeClass('needs-adjustment');
    });
  },
  initQuestionChoices: function() {
    $('.question')
      .filter(':not(.question.answered)')
      .find('.image-figure-image .' + hs._pointerClass)
      .filter(':not(.image-figure-setup)')
      .each(function() {

        var $this = $(this);
        $this.addClass('image-figure-setup');
        var $q = $this.parents('.question');

        $this.on('click', function() {
          var $this = $(this);

          $q.filter('input[type="hidden"].image-figure-answer').remove();
          $q.filter('.' + hs._pointerClass).removeClass('selected-' + hs._pointerClass);
          $('<input type="hidden" class="image-figure-answer">')
            .attr('name', 'answer' + $q.attr('data-question-id'))
            .attr('value', $this.attr('data-answer-id'))
            .appendTo($q)
            .addClass('selected-' + hs._pointerClass);
        });
      });

    $('.question').filter(':not(.question.answered)').find('li.answer a').filter(
      ':not(.answer-link-setup)'
    ).each(function() {
      var $this = $(this);
      $this.addClass('answer-link-setup');

      var $radio = $this.parents('li.answer').find('input[type="radio"]');

      $this.on('click', function(ev) {
        ev.preventDefault();
        $radio.attr('checked', 'checked');
      });

    });

    $('.question.answered input[type="radio"]')
      .filter(':not(.cancelled)')
      .addClass('cancelled')
      .on('click', function(ev) {
        ev.preventDefault();
      }).on('checked', function(ev) {
        ev.preventDefault();
      });
    $('.question.answered form').filter(':not(.cancelled)').addClass('cancelled').on(
      'submit',
      function(ev) {
        ev.preventDefault();
      }
    );

    $('.question.answered li a').filter(':not(.cancelled)').addClass('cancelled').on(
      'click',
      function(ev) {
        ev.preventDefault();
      }
    );

  },
  initOrientationTip: function() {
    var $gn = $('.guide-notification');
    if ($gn.length === 0) {
      return;
    }

    var $om = $('.account-menu a.orientation');
    if ($om.length === 0) {
      return;
    }

    $gn.hide();
    $om.tipTip({keepAlive: true, content: $gn.html()});
    $om.mouseover();
  },
  initWhyLocked: function() {
    $('.thumb.locked').tipTip({keepAlive: true, activation: 'click'});
  },
  initHideTheRest: function() {
    $('.hide-the-rest').each(function() {
      var $t = $(this);

      if ($t.data('done')) {
        return;
      }

      $t.data('done', true);
      var h = $t.height();
      var $p = $t.parent();

      var oldHeight = $p.height();
      var newHeight = $t.scrollTop() + h + parseInt($p.css('padding-top'))
        + parseInt($t.css('padding-top')) + parseInt($t.css('padding-bottom'))
        + parseInt($t.css('margin-top')) + parseInt($t.css('margin-bottom'));

      $p.css({height: newHeight, overflow: 'hidden'});
      $p.data('hide-oldHeight', oldHeight);
      $p.data('hide-newHeight', newHeight);
      $p.data('hide-state', 'new');

      $t.click(function() {
        if ($p.data('hide-state') === 'new') {
          $p.animate({height: $p.data('hide-oldHeight')});
          $('.prompt', $t).hide();
          $p.data('hide-state', 'old');
        } else {
          $p.animate({height: $p.data('hide-newHeight')});
          $('.prompt', $t).show();
          $p.data('hide-state', 'new');
        }
      });

    });

  },
  initUsernameCheck: function() {
    var sel = 'input[id$=_requested_username]';
    $(sel).each(function() {
      if ($(this).data('username-check-initialized')) {
        return;
      }

      var inp = this;
      $(inp).data('username-check-initialized', true).change(function() {
        var url = '/ajax/check-username-available/?username=';
        url += encodeURIComponent($(inp).val());
        $(inp).next('.username-check').remove();
        $.get(url, function(data) {
          $(inp).after(data);
        });
      });

    });
  },
  updateLessonStatus: function() {

    // contains an array of elements by the property they should be filled with
    var byProp = {};

    // get listing of all the elements that are readouts, grouped by what property they show
    $('.lesson-readout').each(function() {
      var prop = $(this).attr('data-prop');
      if (!prop) {
        return;
      }

      if (!byProp[prop]) {
        byProp[prop] = [];
      }

      byProp[prop].push(this);
    });

    // go through each of the properties which had elements showing them
    $.each(byProp, function(prop, elems) {
      // var elems = byProp[prop]; default value if property
      // is missing or blank in the status data
      var dv = '--';
      var t = dv;
      if (hs.lessonStatus) {
        t = hs.lessonStatus[prop] || dv;
      }

      $.each(elems, function(i, elem) {
        var $e = $(elem);
        var oldT = $e.text();

        if (oldT !== t) {
          if ($.trim(oldT) !== '') {
            var w = $e.width();
            var h = $e.height();
            $e.css({width: w, height: h, position: 'relative', overflow: 'hidden'});
            $e.html('');
            var $d = $('<div></div>').appendTo($e).text(t);
            $d.css({
              width: w,
              height: h,
              position: 'absolute',
              top: -h,
              left: 0,
            });
            $d.animate({
              top: 0,
            }, function() {
              $e.html('').text(t).css({
                width: '',
                position: '',
                height: '',
                top: '',
                left: '',
                overflow: '',
              });
            });
          } else {
            $e.text(t);
          }
        }

      });

    });
    // for (var prop in byProp) { }
  },
  initSlideshow: function() {

    var $hasQ = $('.slide-has-questions').hide();
    var $hasNoQ = $('.slide-has-no-questions').hide();
    var $slides = $('#sliding-view > div'); // (... and questions too)

    $slides.removeClass('current');
    $($slides.get(0)).addClass('current');

    function setOffsets() {
      $slides.each(function(i) {
        $(this).data('offset', -1 * $(this).position().left);
        $(this).data('index', i + 1);
      });
    }
    setOffsets();

    $('#slide-count').text('' + $slides.length);

    var findCurrentSlideIndex = function() {
      let at = -1;
      $slides.each(function(i) {
        var $s = $(this);
        if ($s.hasClass('current')) {
          at = i;
        }
      });
      if (at === -1) {
        $('#sliding-view').css('left', 0);
        at = 0;
      }
      return at;
    };

    var updateCurrentSlide = function() {
      var at = findCurrentSlideIndex();
      $('#current-slide').val('' + (at + 1));

      $('#slide-questions .question').hide();
      var slideIndex = $($slides[at]).attr('data-slide-index');
      var $thisSlideQuestions = $(
        '#slide-questions .question.slide-' + slideIndex
          + ', #slide-questions-view .question.slide-'
          + slideIndex
      ).show();

      if ($thisSlideQuestions.length) {
        $hasQ.show('slow');
        $hasNoQ.hide();
      } else {
        $hasQ.hide();
        $hasNoQ.show('slow');
      }
    };

    var loadSlideImages = function($newSlide) {
      // load images for the current and next if necessary
      var loadBig = function() {
        var $i = $(this);
        var src = $i.attr('data-big-src');
        if (!src) {
          return;
        }
        this.removeAttribute('data-big-src');
        this.removeAttribute('data-little-src');
        $i.attr('src', src);
      };
      $('img', $newSlide).each(loadBig);
      $('img', $newSlide.next()).each(loadBig);
    };

    var gotoSlideTyped = function(ev) {
      if (ev) {
        ev.preventDefault();
      }
      var to = parseInt($('#current-slide').val());
      if (to >= 1 && to <= $slides.length) {
        var $newSlide = $($slides[to - 1]);
        var offset = $newSlide.data('offset');
        $slides.removeClass('current')
        $newSlide.addClass('current');

        $('#sliding-view').animate({
          left: offset,
        }, updateCurrentSlide);
        loadSlideImages($newSlide);
      } else {
        updateCurrentSlide();
      }

    };

    $('form#goto-slide').submit(gotoSlideTyped);
    $('#current-slide').blur(gotoSlideTyped);

    let slide = function(direction, cb) {
      var at = findCurrentSlideIndex();
      if ((direction === '+' && at < $slides.length - 1) || (direction === '-' && at > 0)) {
        at += (direction === '+')
          ? 1
          : -1;
        let $newSlide = $($slides.get(at));

        $slides.removeClass('current')
        $newSlide.addClass('current');

        loadSlideImages($newSlide);

        $('#sliding-view').animate({
          left: $newSlide.data('offset'),
        }, function() {
          cb();
          updateCurrentSlide();
        });
      } else {
        let $currentSlide = $($slides.get(at));
        loadSlideImages($currentSlide);
        $('#sliding-view').css({
          left: $currentSlide.data('offset'),
        });

        cb();
      }

    };

    var lockAndSlide = function(ev, direction) {
      ev.preventDefault();

      if (ev && $(ev.target).data('locked')) {
        return;
      }
      $(ev.target).data('locked', true);
      slide(direction, function() {
        $(ev.target).data('locked', false);
      });
    };

    $(window).on('resize', function() {
      setOffsets();
      slide('=', $.noop);
    });

    $('#slide-index button#slide-back').click(function(ev) {
      lockAndSlide(ev, '-');
    });
    $('#slide-index button#slide-forward').click(function(ev) {
      lockAndSlide(ev, '+');
    });

    // advance to last slide answered
    var $lastAnswered = $('#sliding-view > div.answered:last');
    var questionCount = $('#sliding-view > div.question').length;
    var answeredCount = $('#sliding-view > div.answered').length;
    if ($lastAnswered.length > 0 && questionCount > answeredCount) {
      var idx = '' + (
        $lastAnswered.data('index') + 1
      );

      $('#current-slide').val(idx).blur();
    } else {
      updateCurrentSlide();
    }
    $('#sliding-view img').each(function() {
      var $i = $(this);
      var src = $i.attr('data-little-src');
      if (!src) {
        return;
      }
      this.removeAttribute('data-little-src');
      $i.attr('src', src);
    });

    $('#slide-questions-view h2 a').click(function(ev) {
      ev.preventDefault();
      var idx = $(this).attr('href').substring(1);
      $('html, body').animate({
        scrollTop: 0,
      }, 200, function() {
        $('#current-slide').val(idx).blur();
      });
    });
  },
  initAjaxResultBefore: function() {
    $('.ajax-result-before').each(function() {
      if ($(this).data('ajax-result-before-initialized')) {
        return;
      }

      $(this).data('ajax-result-before-initialized', true);
      var url = $(this).attr('data-href');
      var $el = $(this);
      $(this).click(function(ev) {
        ev.preventDefault();
        $.get(url, function(data) {
          $el.before(data);
        });
      });
    });
  },
  /**
 * show a link to the next lesson at appropriate times
 */
  initNextLessonLink: function() {
    $('body').bind('question-answered', function() {
      var $q = $('#pausing_video_questions .question, #slide-questions .question, .non-pausing-video-questions .question');
      if ($q.length > 0 && $q.length === $q.filter('.answered').length) {
        $('.next-lesson').show();
      }
    }).trigger('question-answered');

  },
  initPlot: function() {
    $('.plot').each(function() {
      var $this = $(this);

      if (!$this.attr('data-chart-data')) {

        return;
      }

      var options = {};
      if ($this.hasClass('bar')) {
        $.extend(true, options, {
          seriesDefaults: {
            renderer: $.jqplot.BarRenderer,
            rendererOptions: {
              fillToZero: true,
            },
          },
          axes: {
            xaxis: {
              renderer: $.jqplot.CategoryAxisRenderer,
            },
          },
        });
      }

      var data = JSON.parse($this.attr('data-chart-data'));
      var optionsJSON = ($this.attr('data-chart-options'))
        ? eval('(' + $this.attr('data-chart-options') + ')')
        : null;

      if (optionsJSON) {
        $.extend(true, options, optionsJSON);
      }

      ensureHasId(this);

      $.jqplot(this.id, data, options);
    });
  },
  blockCommenting: function() {
    $('form.comment input[type=submit]').hide();
    $('form.comment textarea').focus(function() {
      $('form.comment .no-commenting').show();
    });
    $('form.comment').unbind('submit').submit(function(ev) {
      ev.preventDefault();
    });
  },
  initCommentSafety: function() {
    if ($('form.comment').length > 0) {
      $('form').each(function() {
        if ($(this).hasClass('comment')) {
          return;
        }
        $(':input', $(this)).change(hs.blockCommenting);
      });
    }
  },
  typesetMath: function() {
    if (!window.MathJax) {
      return;
    }
    $('.needs-math-typesetting').each(function() {
      $(this).data('raw-math', $(this).text());

      window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, this]); // eslint-disable-line new-cap
      $(this).removeClass('needs-math-typesetting');
    });
  },
  logToServer: function(text, retryCount) {
    retryCount = retryCount || 0;
    try {
      var url = $('body').attr('data-logging-url') || window.hshs_logging_url || '/logging/';
      $.post(url, 'm=' + encodeURIComponent(text));
    } catch (e) {
      if (retryCount < 3) {
        setTimeout(function() {
          hs.logToServer(text, retryCount + 1);
        }, 2000);
      }
    }
  },
  initAjaxErrorReadout: function() {
    if ($('#ajax-error, .ajax-error').length > 0) {
      $('#ajax-error, .ajax-error').ajaxError(function(ev, xhr) {
        // TODO: eliminate iframe for prod use

        var doc = $('#ajax-error, .ajax-error').html('<iframe></iframe>')
          .show().find('iframe')[0].contentWindow.document;

        doc.open();
        doc.write(xhr.responseText);
        doc.close();
      });
    }

    $('#ajax-error, .ajax-error').ajaxStart(function() {
      $('#ajax-error, .ajax-error').hide();
    });
  },
  initAjaxForms: function() {

    $('form :button.append-param, form a.append-param').each(function() {

      $(this).unbind('click');

      $(this).click(function(ev) {
        // add the data-param contents to the form action and submit
        ev.preventDefault();
        var param = $(this).attr('data-param');
        var $f = $($(ev.target).parents('form')[0]);
        var parts = param.split('=');
        var name = parts.shift();
        var value = parts.join('=');
        $f.append('<input type="hidden" name="' + name + '" value="' + value + '">');
        $f.submit();
      });
    });

    $('.ajax-reload-container').each(function() {
      console.log('init arc', this);
      let params;
      let url:string;
      let $arc = $(this);

      var $loadTarget = $arc;
      var afterLoad:(response:any, status:any)=>void = $.noop;
      var beforeLoad = function(f:Function) {
        f();
      };

      if ($arc.hasClass('slide-transition')) {
        beforeLoad = function(f) {
          var $old = $('<div class="arc-old"></div>');
          $arc.append($old);
          $old.append($arc.children().not('.arc-old'));
          $old.css({position: 'absolute', top: 0, left: 0,
            width: $arc.width(), height: $arc.height()});
          $loadTarget = $('<div class="arc-new"></div>').appendTo($arc).css({
            position: 'absolute', top: 0, left: $arc.width(),
            width: $arc.width(), height: $arc.height()});
          $arc.css({width: $arc.width(), height: $arc.height(),
            position: 'relative', overflow: 'hidden'});

          f();
        };

        afterLoad = function(response, status): void {
          if (status === 'error') {
            alert('Error occurred');
            $('.arc-new', $arc).remove();
            $('.arc-old', $arc).children().appendTo($arc);
            $('.arc-old', $arc).remove();
            $arc.css({width: '', height: '', position: '', overflow: ''});
          } else {
            $('.arc-new, .arc-old', $arc).animate({
              left: '-=' + $arc.width(),
            }, function() {
              $('.arc-old', $arc).remove();
              $('.arc-new', $arc).children().appendTo($arc).end().remove();
              $arc.css({width: '', height: '', position: '', overflow: ''});
            });
          }
        };

      } else if ($arc.hasClass('fade-transition')) {
        beforeLoad = function(f) {
          $arc.animate({
            opacity: 0.2,
          }, {complete: f});
        };
        afterLoad = function() {
          $arc.animate({opacity: 1.0});
        };
      }

      if ($arc.attr('data-initial-href') && !$arc.data('initial-data-loaded')) {
        $arc.data('initial-data-loaded', true);
        $arc.load($arc.attr('data-initial-href'));
        $arc.on('show-form', function() {
          $arc.load($arc.attr('data-initial-href'));
        });
        return; // ajaxComplete will pick this up again and set everything up later
      }

      $('.show-form', $arc).each(function() {
        if ($(this).data('show-form-processed')) {
          return;
        }
        $(this).data('show-form-processed', true);

        $('form', $arc).hide();
        $('.show-form', $arc).on('click', function(ev) {
          ev.preventDefault();
          $('.show-form', $arc).hide();
          $('form', $arc).show();
          $arc.trigger('show-form');
        });
      });

      $('form', $arc).each(function() {
        if ($(this).data('reload-ready')) {
          return;
        }

        $(this).data('reload-ready', true);

        $(this).find('button, input[type="submit"]').each(function() {
          if (this.type !== 'submit') {
            return;
          } else {
            $(this).on('click', (ev) => {
              if (this.name && this.value) {
                $(this).parents('form').data('submitter', this);
              } else {
                $(this).parents('form').data('submitter', null);
              }
            });
          }
        });

        $(this).on('submit', function(ev) {
          ev.preventDefault();

          let submitter = $(ev.target).data('submitter');
          $(ev.target).data('submitter', null);

          if ($(ev.target).data('already-submitted')) {
            return;
          }
          $(ev.target).data('already-submitted', true);

          var startTime = $arc.data('startTime');
          var elapsed = null;
          if (startTime) {
            elapsed = $.now() - parseInt(startTime);
          }

          var action = $(ev.target).attr('action');
          if (($(ev.target).attr('method') || 'get').toLowerCase() === 'post') {
            params = $(ev.target).serializeArray();
            if (submitter) {
              params.push({name:submitter.name, value:submitter.value});
            }

            if (elapsed !== null) {
              params.push({name: 'time', value: elapsed});
            }

            let formData = new FormData();
            let knownParams = {};
            params.forEach((p) => {
              formData.append(p.name, p.value);
              knownParams[p.name] = true;
            });

            $('input[type="file"]', ev.target).each(function() {
              let $input = $(this);
              let name = $input.attr('name');
              if (knownParams[name]) {
                return;
              }

              let input:any = $input[0];
              for (let i = 0; i < input.files.length; i++) {
                formData.append(name, input.files[i], input.files[i].name);
              }
            });

            beforeLoad(function() {
              $.ajax({
                url: action,
                data: formData,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function(data){
                  $loadTarget.html(data);
                  afterLoad();
                }
              });
            });
          } else {
            params = $(ev.target).serialize();
            if (submitter && params) {
              if (params) {
                params += '&';
              }
              params += submitter.name + '=' + encodeURIComponent(submitter.value);
            }
            url = action + (
              (action.indexOf('?') === -1)
                ? '?'
                : '&'
            ) + params;
            if (elapsed !== null) {
              params += '&time=' + elapsed;
            }
            beforeLoad(function() {
              $loadTarget.load(url, afterLoad);
            });
          }
        });
      });

      $('a', $arc).not('[href^="#"]').not('[target]').not('.no-ajax').not('.arc-ready').addClass('arc-ready')
        .click(function(ev) {
          var that = this;
          ev.preventDefault();
          beforeLoad(function() {
            $loadTarget.load($(that).attr('href'), afterLoad);
          });
        });

      if ($('form', $arc).hasClass('edit-now')) {
        $('form', $arc).removeClass('edit-now');
        $('.show-form', $arc).trigger('click');
      }
    });
  },
  initFigures: function() {
    if ($('#questions .question, #slide-questions .question').length === 0) {
      return;
    }

    $('.figures.follow').followScrolling();

    $('#exam-score-readout.follow').followScrolling();

    if ($('.figures.transient-figures').length > 0) {
      $(window).scroll(function() {
        var $figures = $();
        $('#questions .question').verticallyScrolledIn().each(function() {
          var figIds:any = $(this).attr('data-figures');
          if (!figIds) {
            return;
          }
          figIds = figIds.split(',');

          for (var i = 0; i < figIds.length; i++) {
            $figures = $figures.add($('.figures.transient-figures #' + figIds[i]));
          }
        });

        $('.figures.transient-figures .figure').not($figures).fadeOut(200);
        $figures.not(':visible').fadeIn(200);

        if ($figures.length > 0) {
          $figures.parents('.follow').addClass('showing-content');
        }

        $('.figures.transient-figures').each(function() {
          let $figContainer = $(this);
          if ($figContainer.find('.figure:visible').length === 0) {
            $figContainer.removeClass('showing-content');
          }
        });

      }).scroll();
    }
  },
});

export default hs;

$(hs.init);
