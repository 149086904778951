
if (window.jQuery) {
	(function($) {
		console.log('$ is', $);
		$(function() {
			$('form').not('.double-click-ok')
				.find('.double-click-ok button[type="submit"], .double-click-ok input[type="submit"]')
				.on('click dblclick', function(ev) {

					var $form = $(this);
					if ($form.data('double-click-block')) {
						console.log('blocking double click of form');
						ev.preventDefault();
						return;
					}

					$form.data('double-click-block', true);
					setTimeout(function() {
						try {
							$form.data('double-click-block', false);
						} catch (e) {}
					}, 3000);
				});
		});
	})(jQuery);
}
