if (!window.FEATURES) {
  window.FEATURES = {};
}

(function() {
  console.log('doing feature detection');
  var testNode = document.createElement('div');
  var i;
  var prefixes = ['', '-webkit-', '-moz-', '-ms-'];
  for (i = 0; i < prefixes.length; i++) {
    try {
      testNode.style.position = p + 'sticky';
    } catch (e) {}
    if (testNode.style.position != '') {
      window.FEATURES.sticky = true;
      break;
    }
  }
})();
