export function ensureHasId(elem: Element) {
	if (elem.id) {
		return;
	}
	var id;
	var i = 0;
	var t = Date.now();
	do {
		id = 'i' + (
			(t + i++) % 1000000
		);
	} while (document.getElementById(id));
	elem.id = id;
}
